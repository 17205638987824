import { Route, Routes } from "react-router-dom";
import useScreenSize from "./customHooks/useScreenSize";
import { Suspense, lazy } from "react";
import { Toaster } from "react-hot-toast";
import AdminProtected from "./components/AdminProtected/AdminProtected";

const Login = lazy(() => import("./pages/Login/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Judgement = lazy(() => import("./pages/Judgement/Judgement"));
const Category = lazy(() => import("./pages/Category/Category"));
const SubCategory = lazy(() => import("./pages/SubCategory/SubCategory"));
const Articles = lazy(() => import("./pages/Articles/Articles"));
const Topic = lazy(() => import("./pages/Topic/Topic"));
const Provisions = lazy(() => import("./pages/Provisions/Provisions"));
const VideoContent = lazy(() => import("./pages/VideoContent/VideoContent"));
const Help = lazy(() => import("./pages/Help/Help"));
const UserMaster = lazy(() => import("./pages/UserMaster/UserMaster"));
const RegisteredUsers = lazy(() =>
  import("./pages/RegisteredUsers/RegisteredUsers")
);
const User = lazy(() => import("./pages/User/User"));
const ArticleMaster = lazy(() => import("./pages/ArticleMaster/ArticleMaster"));
const LitigationMaster = lazy(() =>
  import("./pages/LitigationMaster/LitigationMaster")
);
const FormMaster = lazy(() => import("./pages/FormMaster/FormMaster"));
const OTP = lazy(() => import("./pages/OTP/OTP"));
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const LawyerMaster = lazy(() => import("./pages/LawyerMaster/LawyerMaster"));
const Litigation = lazy(() => import("./pages/Litigation/Litigation"));
const AddLitigationPage = lazy(() => import("./pages/AddLitigationPage/AddLitigationPage"));

function App() {
  const screenSize = useScreenSize();
  if (screenSize < 992) {
    return smallScreens();
  }
  return (
    <>
      <Toaster />
      <Suspense fallback={fallbackLoading()}>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/otp" element={<OTP />} />
          <Route
            path="/dashboard"
            element={<AdminProtected Component={Dashboard} />}
          >
            <Route path="judgement" element={<Judgement />} />
            <Route path="videos" element={<VideoContent />} />
            <Route path="help" element={<Help />} />
            <Route path="user-master" element={<UserMaster />} />
            <Route path="article-master" element={<ArticleMaster />} />
            {/* <Route path="litigation-master" element={<LitigationMaster />} /> */}
            <Route path="form-master" element={<FormMaster />} />
            {/* <Route
              path="user-master/registered-users"
              element={<RegisteredUsers />}
            /> */}
            <Route path="user-master/:userId" element={<User />} />
            <Route path="user-master/:userId/:litigationId" element={<Litigation />} />
            <Route path="user-master/:userId/:litigationId/add" element={<AddLitigationPage />} />
            <Route path="user-master/:userId/add" element={<AddLitigationPage />} />

            <Route path="provisions" element={<Provisions />} />
            <Route path="category" element={<Category />} />
            <Route
              path="category/:categoryId/subcategory"
              element={<SubCategory />}
            />
            <Route
              path="category/:categoryId/subcategory/:subcategoryId/articles"
              element={<Articles />}
            />
            <Route
              path="category/:categoryId/subcategory/:subcategoryId/articles/:articleId"
              element={<Topic />}
            />
            <Route path="lawyer-master" element={<LawyerMaster />} />
            <Route path="litigation-master" element={<LitigationMaster />} />

          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;

function smallScreens() {
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center fs-2 fw-bold text-center">
      Please Open this app in Desktop.
    </div>
  );
}

function fallbackLoading() {
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      Loading...
    </div>
  );
}
